.input-url{
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 8px;
}
.shorted-url-list-wrapper{
  margin-top: 32px;
}

@media (min-width: 768px) {
  .input-url{
    grid-template-columns: auto 220px 160px;
  }
}