*{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
html, body{
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;
  font-family: 'Roboto Slab', serif;
}
.App{
  max-width: 820px;
  margin: 0 auto;
  padding: 16px;
}

/* Global */
input, button{
  width: 100%;
  padding: 16px;
  border: 1px solid #222;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none;
}
button{
  background: #222;
  color: #f8f8f8;
  cursor: pointer;
  transition: all 0.2s;
}
button:hover{
  background: #000;
}
.error-msg{
  color: red;
}

/* Animation */
.animate-fade-in-up{
  opacity: 0;
  animation: fade-in-up ease 0.8s forwards;
  -webkit-animation: fade-in-up ease 0.8s forwards;
}
@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
