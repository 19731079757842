.shorted-url-list-item{  
  display: grid;
  grid-template-columns: 1fr 100px;
  column-gap: 8px;
  padding: 16px;
  word-break: break-all;
  background: #efefef;
  border-radius: 4px;
  margin-bottom: 2px;
}
.shorted-url-list-item span{ 
  padding: 2px;
}
.shorted-url-list-item div:first-child span:first-child{
  color: #999;
}
.shorted-url-list-item div:first-child span:last-child{
  font-weight: 800;
}
.shorted-url-list-item div:first-child span{
  display: block;
}
.shorted-url-list-item div:last-child{
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  align-self: center;
}
.shorted-url-list-item ion-icon{
  font-size: 24px;
  cursor: pointer;
  color: #444;
  transition: all 0.22s;
}
.shorted-url-list-item ion-icon:hover{
  color: #000;
}

@media (min-width: 768px) {
  .shorted-url-list-item div:first-child span{
    display: inline-block;
    margin-right: 16px;
  }
}