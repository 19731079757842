.input-text{
  position: relative;
  margin-bottom: 16px;
}
.input-text label{
  position: absolute;
  top: -10px;
  left: 12px;
  transition: all 0.2s;
  touch-action: manipulation;
  color: #999;
  background: #fff;
  padding: 0 4px;
  font-size: 14px;
}
.input-text input,
.input-text label{
  cursor: text;
  outline: 0;
}
.input-text input:placeholder-shown + label {
  transform-origin: left bottom;
  transform: translate(0, 30px) scale(1.2);
}
.input-text input:not(:placeholder-shown) + label,
.input-text input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  background: #fff;
  padding: 0 4px;
}
.input-text ::-webkit-input-placeholder {
  opacity: 0;
  transition: inherit;
}
.input-text input:focus::-webkit-input-placeholder {
  opacity: 1;
}

.error-msg{
  display: block;
}
.input-text.error input{
  border: 1px solid red;
}
.input-text.error .error-msg{
  display: block;
}